import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getBillList } from '@/api/bill';
import MyTooltip from "../../components/elTooltip";
export default {
  name: "FinanceTechnicalBills",
  components: {
    MyTooltip: MyTooltip
  },
  filters: {
    fentoyuan: function fentoyuan(amount) {
      return (amount / 100).toFixed(2);
    }
  },
  data: function data() {
    return {
      data: [],
      loading: false,
      queryParams: {
        start: "",
        end: "",
        time_range: [],
        type: 1,
        keyword: "",
        page: 1,
        pageSize: 10
      },
      total: 0
    };
  },
  created: function created() {
    this.requestData();
  },
  methods: {
    searchReset: function searchReset() {
      this.queryParams = {
        start: "",
        end: "",
        time_range: [],
        type: 1,
        keyword: "",
        page: 1,
        pageSize: 10
      };
      this.requestData();
    },
    requestData: function requestData() {
      var _this = this;

      this.loading = true;
      this.queryParams.start = this.queryParams.time_range[0] || "";
      this.queryParams.end = this.queryParams.time_range[1] || "";
      getBillList(_objectSpread({}, this.queryParams)).then(function (res) {
        _this.loading = false;
        var list = res.data.list;

        if (list && list.length > 0) {
          list.forEach(function (item) {
            if (item.extra) {
              item.extra = JSON.parse(item.extra) || {
                order_count: 0,
                income: 0
              };
            }
          });
        }

        _this.data = list;
        _this.total = res.data.total;
      });
    }
  }
};